import './styles/CompletedCheckout.scss';

import React, { useState, useEffect } from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import { useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

function CompletedCheckout() {
    const { site } = useParams();
    const full_site = `http://storymade.com.br/${site}.html`;

    const copyToClipboard = () => {
        navigator.clipboard.writeText(full_site).then(() => {
            toast.success('Link copiado com sucesso!', {
                position: "top-center",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }, () => {
            toast.error('Falha ao copiar o link.', {
                position: "top-center",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    };

    return (
        <div className="container">
            <div class="card">
                <button class="dismiss" type="button">×</button>
                <div class="header">
                    <div class="image">
                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M20 7L9.00004 18L3.99994 13" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                    </div>
                    <div class="content">
                        <div className="content-title">
                            <span class="title">Pagamento com sucesso!</span>
                        </div>
                        <p class="message">Obrigado pela sua compra!</p>
                        <p class="message">Em alguns instantes, o site personalizado que você adquiriu será enviado para o seu e-mail. Fique de olho na sua caixa de entrada!</p>
                        <p class="message">Caso queira visualizar agora, escaneie o QR code abaixo.</p>
                    </div>
                    <div class="qrcode">
                        <QRCodeCanvas value={full_site} size={150} />
                    </div>
                    <div class="actions">
                        <button class="visit" type="button" onClick={() => window.open(full_site, '_blank')}>Visite o seu site</button>
                        <button class="link" type="button" onClick={copyToClipboard}>Copiar link</button>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        </div>
    );
}

export default CompletedCheckout