import { useState, useEffect } from 'react';
import '../../styles/FormContent.scss';

function FormContent({ onGenerateSite, onFormSubmit }) {
  const [isFormValid, setIsFormValid] = useState(false);
  const [showWarning, setShowWarning] = useState(false);

  const [formData, setFormData] = useState({
    mainPhrase: '',
    coupleName: '',
    relationshipDate: '',
    romanticText: '',
    spotifyLink: '',
    locationLink: '',
    mainPhoto: []
  });

  const handlePreviewSiteButtonClick = () => {
    if (!isFormValid) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
      onGenerateSite(formData);
      onFormSubmit();
    }
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // Verifica se o campo é 'relationshipDate' e se a data não é futura
    if (name === 'relationshipDate') {
      const selectedDate = new Date(value);
      const today = new Date();

      if (selectedDate > today) {
        alert('A data de início do relacionamento não pode ser no futuro.');
        return;
      }
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handlePhotoChange = (event) => {
    const files = event.target.files;
    let photoUrls = [];

    if (files.length > 0) {
      for (let i = 0; i < Math.min(files.length, 4); i++) {
        const photoUrl = URL.createObjectURL(files[i]);
        photoUrls.push(photoUrl);
      }
    }

    setFormData((prevData) => ({
      ...prevData,
      mainPhoto: photoUrls
    }));
  };

  // Verifica se todos os campos estão preenchidos para habilitar o botão
  useEffect(() => {
    const isValid =
      formData.mainPhrase &&
      formData.coupleName &&
      formData.relationshipDate &&
      formData.romanticText &&
      formData.spotifyLink &&
      formData.mainPhoto.length > 0;

    setIsFormValid(isValid);
  }, [formData]);

  return (
    <div className="form-content">
      <div>
        <label htmlFor="mainPhrase">Frase principal</label>
        <input
          type="text"
          id="mainPhrase"
          name="mainPhrase"
          placeholder='Seu sorriso é o meu lugar favorito... ❤️'
          value={formData.mainPhrase}
          onChange={handleInputChange}
        />
      </div>

      <div>
        <label htmlFor="coupleName">Nome do casal</label>
        <input
          type="text"
          id="coupleName"
          name="coupleName"
          placeholder='Brenda e Mateus'
          value={formData.coupleName}
          onChange={handleInputChange}
        />
      </div>

      <div className='date-time'>
        <div className="date-time-label">
          <label htmlFor="relationshipDate">Início do relacionamento</label>
        </div>
        <div className="date-time-input">
          <input
            type="date"
            id="relationshipDate"
            name="relationshipDate"
            value={formData.relationshipDate}
            onChange={handleInputChange}
            max={new Date().toISOString().split('T')[0]} // Define a data máxima como a data atual
          />
        </div>
      </div>

      <div>
        <label htmlFor="spotifyLink">Link da playlist do Spotify</label>
        <input
          type="url"
          id="spotifyLink"
          name="spotifyLink"
          placeholder='https://open.spotify.com/playlist/1sSp2TyERRj7a4DBc130Vb?si=cd03b0e9a8e9459d'
          value={formData.spotifyLink}
          onChange={handleInputChange}
        />
      </div>

      <div>
        <label htmlFor="romanticText">Texto romântico</label>
        <textarea
          id="romanticText"
          name="romanticText"
          placeholder='Escreva uma linda mensagem para o seu amor'
          value={formData.romanticText}
          onChange={handleInputChange}
        />
      </div>

      <div className="photo-input">
        <label htmlFor="mainPhoto" className="custom-file-upload">
          <svg viewBox="0 0 640 512" fill="white" height="1em" xmlns="http://www.w3.org/2000/svg"><path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"></path></svg>
          Selecionar fotos do casal (4 máximo)
        </label>
        <input type="file" id="mainPhoto" name="mainPhoto" accept="image/*" multiple onChange={handlePhotoChange} />
      </div>

      <div className="submit-btn">
        <button
          type="submit"
          onClick={handlePreviewSiteButtonClick}
          className={isFormValid ? 'active' : ''}>Visualizar prévia
        </button>

        {/* Só mostrar a mensagem se o usuário tentar clicar no botão desabilitado */}
        {showWarning && (
          <p className="warning-message">
            Preencha todos os campos do formulário para continuar.
          </p>
        )}
      </div>
    </div>
  );
}

export default FormContent;