import Main from './components/Main';
import Step from './components/steps/Step';
import Form from './components/form/Form';
import Footer from './components/footer/Footer'
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import CompletedCheckout from './CompletedCheckout';

function Home() {
  return (
    <div className="App">
      <Main />
      <Step />
      <Form />
      <Footer />
    </div>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/checkout-completed/:site" element={<CompletedCheckout />} />
      </Routes>
    </Router>
  );
}

export default App;