import '../styles/Main.scss';

import Header from './header/Header.jsx';
import Hero from './hero/Hero.jsx';

function Main(){
    return (
        <div className="main">
            <Header />
            <Hero />
        </div>
    )
}

export default Main;