import '../../styles/Header.scss';

import Logo from './Logo.jsx';
import Nav from './Nav.jsx';
import ContactButton from './ContactButton.jsx';

function Header ({ navmenu=true }){
    return (
        <header>
            <Logo />
            { navmenu && <Nav /> }
            <ContactButton />
        </header>
    );
}

export default Header