import '../../styles/Hero.scss'

import HeroSection from "./HeroSection"
import HeroContent from "./HeroContent"

function Hero (){
    return (
        <div className="hero">
            <HeroSection />
            <HeroContent />
        </div>
    )
}

export default Hero;