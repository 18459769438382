import '../../styles/HeroSection.scss'

function HeroSection() {	
    const scrollTo = (id) => {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return (
        <section className="hero-section">
            <h1>Mostre ao mundo a história do seu relacionamento </h1>
            <div className="content">
                <p>
                    Em apenas alguns minutos, transforme seu romance em algo inesquecível!
                    <br/> <br/> 
                    Crie uma página exclusiva, personalize com fotos, playlist, mensagens e muito mais...📱✨
                    <br/> <br/> Fácil de criar, lindo de compartilhar... ❤️ 
                </p>
            </div>
            <div className="btn-actions">
                <button><a onClick={() => scrollTo('steps')} >Como funciona</a></button>
                <button><a onClick={() => scrollTo('create-site')} >Crie seu site</a></button>
            </div>
        </section>
    )
}

export default HeroSection