import "../../styles/ContactButton.scss";

function ContactButton() {
  return (
    <div className="contact-brn">
      <a href="mailto:suporte.storymade@gmail.com" className="cta">
        <span>Contato</span>
        <svg width="15px" height="10px" viewBox="0 0 13 10">
          <path d="M1,5 L11,5"></path>
          <polyline points="8 1 12 5 8 9"></polyline>
        </svg>
      </a>
    </div>
  );
}

export default ContactButton;
