import '../../styles/Nav.scss';

function Nav (){
    const scrollTo = (id) => {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };

    return (
        <nav>
            <a onClick={() => scrollTo('steps')}>Como funciona</a>
            <a onClick={() => scrollTo('create-site')}>Crie seu site </a>
            {/* <a>Sobre</a> */}
        </nav>
    )
}

export default Nav