import '../../styles/Form.scss'
import React, { useState } from 'react';
import FormContent from './FormContent';
import { loadStripe } from '@stripe/stripe-js';
import { FiLoader } from "react-icons/fi";

// Stripe public key 
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function Form() {
  const [iframeContent, setIframeContent] = useState(null);
  const [relationshipDate, setRelationshipDate] = useState(null);
  const [showCreateSiteButton, setShowCreateSiteButton] = useState(false);
  const [generatedHtml, setGeneratedHtml] = useState('');
  const [siteCoupleName, setSiteCoupleName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showWarning, setShowWarning] = useState(false);

  const handleGenerateSiteButtonClick = () => {
    if (!showCreateSiteButton || isLoading) {
      setShowWarning(true);
    } else {
      exportHTMLFile();
      setShowWarning(false)
    }
  }

  const handleFormSubmit = () => {
    setShowCreateSiteButton(true);
  };

  const formatRelationShipDate = (date) => {
    if (date === '') {
      return 'dd.mm.aaaa';
    }

    const [year, month, day] = date.split('-');
    return `${day}.${month}.${year}`;
  };

  const formatSpotifyPlaylistURL = (url) => {
    const playlistID = url.split('playlist/')[1];
    return `https://open.spotify.com/embed/playlist/${playlistID}?utm_source=generator&theme=0`;
  };

  const loadHTMLTemplate = async () => {
    const response = await fetch(`${process.env.PUBLIC_URL}/site/site.html`);
    const html = await response.text();
    return html;
  };

  const generateSiteHTML = async (formData) => {
    const mainPhrase = formData.mainPhrase ? formData.mainPhrase : 'Frase principal';
    const coupleName = formData.coupleName ? formData.coupleName : 'Nome do casal';
    const relationshipDate = formData.relationshipDate;
    const romanticText = formData.romanticText ? formData.romanticText : 'Texto romântico para seu parceiro(a)';
    const spotifyLink = formData.spotifyLink;
    const mainPhoto = formData.mainPhoto;

    setSiteCoupleName(coupleName);
    setRelationshipDate(relationshipDate);

    let html = await loadHTMLTemplate();

    const mainPhotoBase64 = await convertToBase64(mainPhoto[0]);
    const secundaryPhotoBase64 = await convertToBase64(mainPhoto[1]);
    const thirdPhotoBase64 = await convertToBase64(mainPhoto[2]);
    const fourthPhotoBase64 = await convertToBase64(mainPhoto[3]);

    html = html
      .replace('Frase principal', mainPhrase)
      .replace('Nome do casal', coupleName)
      .replace('dd.mm.aaaa', formatRelationShipDate(relationshipDate))
      .replace('Texto romântico para seu parceiro(a)', romanticText)
      .replace('https://open.spotify.com/embed/playlist/37i9dQZF1EVGJJ3r00UGAt?si=e036145ebc2c48b4?utm_source=generator&theme=0', formatSpotifyPlaylistURL(spotifyLink))
      .replace('2021-01-01', relationshipDate)
      .replace('../images/image2.png', mainPhotoBase64)
      .replace('../images/image1.png', secundaryPhotoBase64)
      .replace('../images/image1.png', secundaryPhotoBase64)
      .replace('../images/image3.png', thirdPhotoBase64)
      .replace('../images/image3.png', thirdPhotoBase64)
      .replace('../images/image4.png', fourthPhotoBase64)
      .replace('../images/image4.png', fourthPhotoBase64);

    // Carregar os arquivos CSS e JS
    const css = await loadFile('/site/style.css');
    const js = await loadFile('/site/script.js');
    const hearts = await loadFile('/site/particles-hearts.js');

    html = `
      <html>
        <head>
          <style>${css}</style>
        </head>
        <body>
          ${html}
          <script src="https://cdn.jsdelivr.net/particles.js/2.0.0/particles.min.js"></script>
          <script>${hearts}</script>
          <script>${js}</script>
        </body>
      </html>
    `;

    setIframeContent(html);
    setGeneratedHtml(html);
  };

  const loadFile = async (filePath) => {
    const response = await fetch(filePath);
    return await response.text();
  };

  const convertToBase64 = (blobUrl) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        const reader = new FileReader();
        reader.onloadend = function () {
          resolve(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open('GET', blobUrl);
      xhr.responseType = 'blob';
      xhr.send();
    });
  };

  const exportHTMLFile = async () => {
    setIsLoading(true);

    try {
      const response = await fetch(process.env.REACT_APP_FILE_API, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          htmlContent: generatedHtml,
          fileName: siteCoupleName,
        }),
      });

      const data = await response.json();
      const fileName = data.fileName;

      if (response.ok) {
        await redirectToCheckout(fileName);
      }
    } catch (error) {
      console.error('Erro ao exportar o arquivo HTML:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const redirectToCheckout = async (fileName) => {
    const stripe = await stripePromise;

    try {
      const response = await fetch(process.env.REACT_APP_STRIPE_CHECKOUT_API, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', },
        body: JSON.stringify({
          file: fileName,
        }),
      });

      const data = await response.json();

      if (data.error) {
        console.error('Erro ao criar sessão de checkout:', data.error);
        return;
      }

      // Redireciona o usuário para o Stripe Checkout
      const result = await stripe.redirectToCheckout({
        sessionId: data.id,
      });

      if (result.error) {
        console.error(result.error.message);
      }
    } catch (error) {
      console.error('Erro ao redirecionar para o Stripe Checkout:', error);
    }
  };

  return (
    <div className="form-container" id="create-site">
      <div className="form-container-title">
        <h1>Crie seu site ✨</h1>
        <p>Preencha o formulário abaixo e visualize a prévia do seu site</p>
      </div>

      <div className="form-container-content">
        <div className="site-preview-container">
          <p>Seu site ficará assim 👇</p>
          <div className="site-preview">
            {iframeContent ? (
              <iframe
                srcDoc={iframeContent}
                style={{ width: '100%', height: '100%', border: 'none' }}
              />
            ) : (
              <iframe
                src={`${process.env.PUBLIC_URL}/site/site.html`}
                style={{ width: '100%', height: '100%', border: 'none' }}
              />
            )}
          </div>
        </div>

        <div className="form">
          <FormContent onGenerateSite={generateSiteHTML} onFormSubmit={handleFormSubmit} />
        </div>
      </div>

      <div className="generate-site-btn">
        <span>Após conferir a prévia do seu site, basta clicar no botão abaixo 🎉</span>
        <button
          className={showCreateSiteButton ? 'active' : ''}
          onClick={handleGenerateSiteButtonClick}
        >
          {isLoading ? (
            <>
              <FiLoader className="spinner-icon" /> Redirecionando para o pagamento...
            </>
          ) : (
            'Criar meu site personalizado'
          )}
        </button>

        {/* Só mostrar a mensagem se o usuário tentar clicar no botão desabilitado */}
        {showWarning && !showCreateSiteButton && (
          <p className="warning-message">
            Preencha todos os campos do formulário para continuar.
          </p>
        )}

      </div>
    </div>
  );
}

export default Form;