import '../../styles/Step.scss'

import pen from '../../assets/pen.png'
import card from '../../assets/card.png'
import email from '../../assets/email.png'
import heart from '../../assets/heart.png'

function Step (){
    return (
        <div className="container" id='steps'>
            <div className="container-title">
                <h1>Como funciona? 💡</h1>
            </div>

            <div className="container-content">
                
                <div className="box-item">
                    <div className="item">
                        <img src={pen} alt="item1"/>
                    </div>
                    <span>1. Preencha o formulário</span>
                </div>
                <div className="separator"></div>

                <div className="box-item">
                    <div className="item">
                        <img src={card} alt="item1"/>
                    </div>
                    <span>2. Realize o pagamento</span>
                </div>
                <div className="separator"></div>

                <div className="box-item">
                    <div className="item">
                        <img src={email} alt="item1"/>
                    </div>
                    <span>3. Receba o site por e-mail + QR Code</span>
                </div>
                <div className="separator"></div>

                <div className="box-item">
                    <div className="item">
                        <img src={heart} alt="item1"/>
                    </div>
                    <span>4. Surpreenda quem você ama</span>
                </div>
            </div>
        </div>
    )
}

export default Step;
