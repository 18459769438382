import '../../styles/Logo.scss';
import iphoneImage from '../../assets/iphone.png';

function Logo() {
    return (
        <div className="logo-container">
            <img src={iphoneImage} alt="Imagem do iPhone" />
            <div className="separator"></div> 
            <span>StoryMade</span>
        </div>
    );
}

export default Logo;
