import '../../styles/HeroContent.scss';
import video from '../../assets/video-inicio.mp4';

function HeroContent() {
    return (
        <section className='hero-content'>
            <div className="image-container">
                <img src="https://cdn.prod.website-files.com/63d0f8b43ca85422d48a69d6/63d81b4b706f9e5ef2013ca1_iPhone%2014%20Pro.png" alt="iPhone image" loading="eager" className="image-4"/>
                <video className="video-container" autoPlay playsInline muted={true} loop preload='auto'>
                    <source src={video} type="video/mp4" />
                    </video>
            </div>
        </section>
    );
}

export default HeroContent;
